<i18n locale="en" lang="yaml">
page.title : "{name} :: User Profile"
company.card.title : "Company"
</i18n>
<i18n locale="th" lang="yaml" >
page.title : "เปลี่ยนรหัสผ่าน"

field.current_password : "รหัสผ่านปัจจุบัน"
field.current_password.placeholder : "@:field.current_password"
field.new_password : "รหัสผ่านใหม่"
field.new_password.placeholder : "@:field.new_password"
field.confirmed_password : "ยืนยันรหัสผ่าน"
field.confirmed_password.placeholder : "@:field.confirmed_password"
change_password.button : "เปลี่ยนรหัสผ่าน"
change_password.success : "ทำการเปลี่ยนรหัสผ่านเรียบร้อย"
validate.confirmed_password.error : "รหัสผ่านไม่ตรงกัน"

</i18n>

<template>
	<div class="page">
		<my-page-header
			:title="$t('page.title')"/>

		<a-card :bordered="false" :loading="pageLoading">
			<a-form
				layout="horizontal"
				:label-col="labelCol"
				:wrapper-col="wrapperCol"
				:form="formObj"
				@submit.prevent="handleSubmit">
					<input name="fakeusernameremembered" class="hidden"/>
					<input type="password" name="fakepasswordremembered" class="hidden"/>

					<a-form-item :label="$t('field.current_password')">
						<a-input-password
							v-decorator="[
								'current_password' ,
								{ rules: [
									{ required: true, message: $tt('validate.required','field.current_password') },
									{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: $t('common.password.pattern') },
								] },
							]"
							autocomplete="new-password"
							type="password"
							:placeholder="$t('field.current_password.placeholder')">
							<ion-icon slot="prefix" name="key-outline" class="anticon" />
						</a-input-password>
					</a-form-item>
					<hr />
					<a-form-item :label="$t('field.new_password')" :extra="$t('common.password.pattern')">
						<a-input-password
							v-decorator="[
								'new_password' ,
								{ rules: [
									{ required: true, message: $tt('validate.required','field.new_password') },
									{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: $t('common.password.pattern') },
								] },
							]"
							autocomplete="new-password"
							type="password"
							:placeholder="$t('field.new_password.placeholder')">
							<ion-icon slot="prefix" name="key-outline" class="anticon" />
						</a-input-password>
					</a-form-item>

					<a-form-item :label="$t('field.confirmed_password')">
						<a-input-password
							v-decorator="[
								'confirmed_password' ,
								{
									rules: [
										{ required: true, message: $tt('validate.required','field.confirmed_password') },
										{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: $t('common.password.pattern') },
										{ validator: validateConfirmPassword } ,
									],
								},
							]"
							autocomplete="new-password"
							type="password"
							:placeholder="$t('field.confirmed_password.placeholder')">
							<ion-icon slot="prefix" name="key-outline" class="anticon" />
						</a-input-password>
					</a-form-item>

					<a-form-item v-bind="wrapperOnlyLayout">
						<a-button size="large" icon="left" :disabled="formLoading" style="margin-right:4px" @click="goBack">
							{{$t('common.back')}}
						</a-button>
						<a-button type="info" html-type="submit" class="btn-submit" size="large" :loading="formLoading">
							<a-icon type="save" /> {{$t('change_password.button')}}
						</a-button>
					</a-form-item>
				</a-form>
		</a-card>
	</div>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import PageMixin from "@mixins/PageMixin.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"

import axios from "axios"

export default {
	mixins : [HasAntdFormMixin,PageMixin,LoginInfoMixin] ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	methods : {
		validateConfirmPassword(rule, value, callback) {
			if (value && value !== this.formObj.getFieldValue('new_password')) {
        callback(this.$t('validate.confirmed_password.error'));
      } else {
        callback();
      }
		} ,
		goBack() {
			this.$open({name:'user/view',params:{id:this.currentUser.id}})
		} ,
		handleSubmit() {
			this.formObj.validateFields((err, values) => {
				if (!err) {
					this.formLoading = true
					const formData = {...values};

					axios.post("/api/users/"+this.currentUser.id+"/change-password",formData).then((response) => {
						this.$router.push({
							name : 'user/view',
							params : {id : this.currentUser.id}
						});
						this.$message.success(this.$t('change_password.success',{"name" : this.currentUser.username}))
					}).catch((error) => {
						this.formSubmitErrors(error)
					}).finally(()=>{
						this.formLoading = false;
					});
				} else {
					this.displayValidateErrorMessage()
				}
			})
		}
	} ,
}
</script>
